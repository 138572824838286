<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="sendFilesForm"
        ref="sendFilesForm"
        class="w-100 pt-6"
        @submit.prevent="submitSendFilesForm"
      >
        <v-col cols="12">
          <v-progress-linear
            v-if="isFetchingClient"
            indeterminate
            absolute
            top
            height="3"
          />
          <ForwardDocument
            :disabled="isFetchingClient"
            :contact-emails="contactEmails"
            @update="updateContacts"
          />
        </v-col>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :disabled="!contactEmails.length || isFetchingClient"
        :loading="isProcessing"
        type="submit"
        form="sendFilesForm"
      >
        Wyślij pliki
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import ForwardDocument from '../Forms/Partials/ForwardDocument'
import rules from '../../utils/validators'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    DialogFormWrapper,
    ForwardDocument
  },
  data() {
    return {
      rules,
      contactEmails: [],
      isFetchingClient: false,
    }
  },
  async mounted() {
    this.clearSingleClient()
    if (this.order.client) {
      this.isFetchingClient = true
      await this.getClient(this.order.client.id)
      this.isFetchingClient = false
    }
  },
  beforeDestroy() {
    this.clearSingleClient()
  },
  computed: {
    ...mapState({
      isProcessing: state => state.order.isProcessing,
      order: state => state.layout.dialog.item,
      fileIds: state => state.files.selectedItems,
      clientContacts: state => state.client.entity?.contacts
    })
  },
  methods: {
    ...mapActions({
      getClient: 'client/getSingleClient',
      sendFiles: 'order/sendFiles',
      clearSingleClient: 'client/clearSingleClient',
      unselectAllItems: 'files/unselectAllItems'
    }),
    updateContacts(contactEmails) {
      this.contactEmails = contactEmails
    },
    submitSendFilesForm() {
      if (this.$refs.sendFilesForm.validate()) {
        this.sendFiles({
          contactEmails: this.contactEmails,
          ids: this.fileIds
        }).then(() => {
          this.unselectAllItems()
        })
      }
    }
  }
}
</script>
